import axios from "axios";
import axiosInstance from "../lib/axiosConfig";

// Function to get all students
export const getAllStudents = async ({ offset = 0, limit = 10 }) => {
  try {
    const { data } = await axiosInstance.get('students/', {
      params: {
        offset,
        limit,
      },
    });
    return data;
  } catch (error) {
    console.error("Error fetching students:", error);
    throw error; // Re-throw the error for further handling
  }
};

// Function to create a student
export const createStudent = async (newStudent) => {
  try {
    const response = await axiosInstance.post('students/', newStudent);
    return response.data;
  } catch (error) {
    console.error("Error creating student:", error);
    throw error;
  }
};

// // Function to delete a student
export const deleteStudent = async (studentId) => {
  try {
    const response = await axiosInstance.delete(`students/${studentId}`);
    return response.data;

  } catch (error) {
    console.error("Error deleting student:", error);
    throw error;
  }
};


// Function to edit a student
export const editStudent = async (studentId, updatedData) => {
  try {
    const response = await axiosInstance.put(`students/${studentId}/`, updatedData);
    return response.data;
  } catch (error) {
    console.error("Error editing student:", error);
    throw error;
  }
};

// Function to get a single student
export const getStudentById = async (studentId) => {
  try {
    const response = await axiosInstance.get(`students/${studentId}/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching student:", error);
    throw error;
  }
}