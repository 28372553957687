import axiosInstance from "../lib/axiosConfig";

import axios from 'axios';
import qs from 'qs'; // Import qs for URL-encoding

export const getToken = async (credentials) => {
  try {
    const response = await axiosInstance.post('auth/token', qs.stringify({
      grant_type: 'password',
      username: credentials.username,
      password: credentials.password,
      scope: '',
      client_id: 'string', // Replace with actual client_id
      client_secret: 'string' // Replace with actual client_secret
    }), {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating token:", error);
    throw error;
  }
};
