import { useState, useMemo, useCallback } from "react";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { deleteStudent, editStudent, getAllStudents } from "../Api/students";
import { DateTime } from "luxon";
import usePagination from "./usePagination";
import { Link } from "react-router-dom";

export const useStudentsTable = () => {
  const queryClient = useQueryClient();
  const { limit, offset, currentPage, goToPage, nextPage, prevPage } =
    usePagination();
  // Fetching data using TanStack Query with pagination
  const {
    data = {
      data: [],
      metadata: { count: 0, offset: 0, limit: 10, total_pages: 1 },
    },
    error,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["students", offset, limit], // Add offset and limit to query key for re-fetching when they change
    queryFn: () => getAllStudents({ offset, limit }), // Adjust API call to include offset and limit
    select: (response) => response,
    keepPreviousData: true, // Keep previous data while fetching new data
    staleTime: Infinity,
  });

  // Provide default values to prevent undefined errors
  const totalItems = data.metadata?.count || 0;
  const totalPages = Math.ceil(totalItems / limit);

  // Mutation for deleting a student
  const deleteMutation = useMutation({
    mutationKey: ["deleteStudent"],
    mutationFn: deleteStudent,
    onSuccess: () => {
      // Invalidate and refetch students data after deleting
      queryClient.invalidateQueries(["students"]);
    },
  });

  // Mutation for editing a student
  const editMutation = useMutation({
    mutationFn: ({ id, updatedData }) => editStudent(id, updatedData),
    onSuccess: () => {
      // Invalidate and refetch students data after editing
      queryClient.invalidateQueries(["students"]);
    },
  });

  const handleDelete = useCallback(
    (studentId) => {
      deleteMutation.mutate(studentId);
    },
    [deleteMutation]
  );

  const handleEdit = useCallback(
    (studentId, updatedData) => {
      editMutation.mutate(studentId, updatedData);
    },
    [editMutation]
  );

  // Defining table columns
  const columns = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllPageRowsSelected()}
            onChange={table.getToggleAllPageRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        id: "name",
        header: "Name",
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        id: "dob",
        header: "Date of Birth",
        accessorKey: "dob",
        // cell: (info) =>
        //   DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
      },
      {
        id: "emis_id",
        header: "Emis Id",
        accessorKey: "emis_id",
      },
      {
        id: "gender",
        header: "Gender",
        accessorKey: "gender",
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
      },
      {
        id: "admitted_on",
        header: "Admitted On",
        accessorKey: "admitted_on",
        cell: (info) =>
          DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
      },
      {
        id: "action",
        header: "Action",
        cell: ({ row }) => (
          <>
            <button>
              <Link to={`/students/edit/${row.original.id}`}>Edit</Link>
            </button>
            <button onClick={() => handleDelete(row.original.id)}>
              Delete
            </button>
          </>
        ),
      },
    ],
    []
  );

  return {
    columns,
    data,
    isLoading,
    isFetching,
    error,
    pagination: {
      currentPage,
      totalPages,
      nextPage: () => nextPage(totalPages),
      prevPage,
      goToPage: (page) => goToPage(page, totalPages),
    },
    editMutation,
    deleteMutation,
  };
};
