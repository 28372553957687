import React, { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { getToken } from '../Api/userServices';

// Create AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => {
    // Initialize user from localStorage if available
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const loginMutation = useMutation({
    mutationFn: (credentials) => getToken(credentials),
    onSuccess: (data) => {
      setUser(data);
      localStorage.setItem('user', JSON.stringify(data));
      navigate('/dashboard');
    },
    onError: (error) => {
      console.error('Login failed:', error);
      alert('Invalid credentials');
    },
  });
  

  // Handle login using React Query mutation
  const login = (credentials) => {
    loginMutation.mutate(credentials);
  };

  // Handle logout
  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    navigate('/login');
  };

  // Optionally, you can add logic to refresh tokens here

  return (
    <AuthContext.Provider value={{ user, login, logout, isLoading: loginMutation.isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
