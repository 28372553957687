import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createStudent, editStudent, getStudentById } from "../../Api/students"; // API call function for POST request
import { useNavigate, useParams } from "react-router-dom";

const StudentForm = () => {
  const queryClient = useQueryClient();
  const { id } = useParams(); // Get the route parameter
  const navigate = useNavigate();
  // const [isEditMode, setIsEditMode] = useState(false);
  // Initialize React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

const { data: selectedStudent } = useQuery({
  queryKey: ["students", id],
  queryFn: () => getStudentById(id),
  enabled: !!id,
});

  // Whenever the selected student changes, reset the form with the new data
  useEffect(() => {
    if (selectedStudent) {
      reset(selectedStudent); // Populate the form fields with selected student data
    }
  }, [selectedStudent, reset]);

  const onSubmit = (Student) => {
    if (id) {
      // Edit mode
      editMutation.mutate({ id, updatedData: Student });
    } else {
      // Create mode
      createMutation.mutate(Student);
    }
    navigate("/students");
  };

  // Mutation for submitting the form
  const createMutation = useMutation({
    mutationFn: createStudent, // Ensure you're passing the function this way
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]); // Refetching students after success
    },
  });

  // Mutation for editing a student
  const editMutation = useMutation({
    mutationFn: editStudent, // Ensure you're passing the function this way
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]); // Refetching students after success
    },
  });

  // // Form submit handler
  // const onSubmit = (data) => {
  //   createMutation.mutate(data);
  // };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-lg">
        <h1 className="text-2xl font-semibold mb-6 text-center">
          Student Registration
        </h1>
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          {/* First Name */}
          <div>
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              {...register("first_name", {
                required: "First Name is required",
              })}
            />
            {errors.first_name && (
              <p className="text-red-500 text-sm mt-1">
                {errors.first_name.message}
              </p>
            )}
          </div>

          {/* Last Name */}
          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              {...register("last_name", { required: "Last Name is required" })}
            />
            {errors.last_name && (
              <p className="text-red-500 text-sm mt-1">
                {errors.last_name.message}
              </p>
            )}
          </div>

          {/* Date of Birth */}
          <div>
            <label
              htmlFor="dob"
              className="block text-sm font-medium text-gray-700"
            >
              Date of Birth
            </label>
            <input
              type="date"
              id="dob"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              {...register("dob")}
            />
            {errors.dob && (
              <p className="text-red-500 text-sm mt-1">{errors.dob.message}</p>
            )}
          </div>

          {/* Emis ID */}
          <div>
            <label
              htmlFor="emis_id"
              className="block text-sm font-medium text-gray-700"
            >
              Emis ID
            </label>
            <input
              type="text"
              id="emis_id"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              {...register("emis_id", { required: "Emis ID is required" })}
            />
            {errors.emis_id && (
              <p className="text-red-500 text-sm mt-1">
                {errors.emis_id.message}
              </p>
            )}
          </div>

          {/* Gender */}
          <div>
            <label
              htmlFor="gender"
              className="block text-sm font-medium text-gray-700"
            >
              Gender
            </label>
            <select
              id="gender"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              {...register("gender", { required: "Gender is required" })}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {errors.gender && (
              <p className="text-red-500 text-sm mt-1">
                {errors.gender.message}
              </p>
            )}
          </div>

          {/* Status */}
          <div>
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              Status
            </label>
            <select
              id="status"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              {...register("status", { required: "Status is required" })}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            {errors.status && (
              <p className="text-red-500 text-sm mt-1">
                {errors.status.message}
              </p>
            )}
          </div>

          {/* Admitted On */}
          <div>
            <label
              htmlFor="admitted_on"
              className="block text-sm font-medium text-gray-700"
            >
              Admitted On
            </label>
            <input
              type="date"
              id="admitted_on"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              {...register("admitted_on", {
                required: "Admitted Date is required",
              })}
            />
            {errors.admitted_on && (
              <p className="text-red-500 text-sm mt-1">
                {errors.admitted_on.message}
              </p>
            )}
          </div>

          {/* Address */}
          <div>
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              {...register("address")}
            />
            {errors.address && (
              <p className="text-red-500 text-sm mt-1">
                {errors.address.message}
              </p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600 transition"
            disabled={createMutation.isLoading}
          >
            {createMutation.isLoading ? "Submitting..." : "Submit"}
          </button>

          {/* Submission status */}
          {createMutation.isError && (
            <p className="text-red-500 mt-2">
              An error occurred while submitting the form.
            </p>
          )}
          {createMutation.isSuccess && (
            <p className="text-green-500 mt-2">Student added successfully!</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default StudentForm;
