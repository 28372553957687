// src/routes.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Profile from "./pages/Profile";
import ListStudents from "./pages/Student/ListStudent";
import CreateStudent from "./pages/Student/CreateStudent";
import StudentForm from "./components/Student/StudentForm";
import { AuthProvider } from "./context/authContext";
import ProtectedRoute from "./components/auth/ProtectedRoute";

import Login from "./pages/Auth/Login.js";
const AppRoutes = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
            {/* Grouped Students Routes */}
            <Route path="students">
              <Route path="list" element={<ListStudents />} />
              <Route path="create" element={<CreateStudent />} />
              <Route path="edit/:id" element={<StudentForm />} />
            </Route>

            <Route path="settings" element={<Settings />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;
