// src/pages/Profile.js
import React, { useMemo } from "react";
import Table from "../components/common/Table";
import students from "../../src/data.json";
import { DateTime } from "luxon";

const Profile = () => {
  const data = useMemo(() => students, []);

  // console.log(data);

  const columns = [
    {
      // Checkbox column
      id: 'select',
      header: ({ table }) => (
        <input
          type="checkbox"
          checked={table.getIsAllPageRowsSelected()}
          onChange={table.getToggleAllPageRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <input
          type="checkbox"
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
    },
    {
      // Name column with full name
      id: 'name',
      header: 'Name',
      accessorFn: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
      // Date of Birth column with formatted date
      id: 'dob',
      header: 'Date of Birth',
      accessorKey: 'dob',
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      // Gender column
      id: 'gender',
      header: 'Gender',
      accessorKey: 'gender',
    },
    {
      // Action column with Edit and Delete buttons
      id: 'action',
      header: 'Action',
      cell: ({ row }) => (
        <>
          <button onClick={() => handleEdit(row.original)}>Edit</button>
          <button onClick={() => handleDelete(row.original)}>Delete</button>
        </>
      ),
    },
  ];
  
  // Example action handlers
  const handleEdit = (rowData) => {
    // Implement edit functionality
    console.log('Edit row:', rowData);
  };
  
  const handleDelete = (rowData) => {
    // Implement delete functionality
    console.log('Delete row:', rowData);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold">Profile</h2>
      <p className="mt-4">View and edit your profile here.</p>
      <Table data={data} columns={columns} />
    </div>
  );
};

export default Profile;
