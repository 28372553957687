import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  const [openSubmenus, setOpenSubmenus] = useState({
    students: false,
    settings: false, // Add more as needed
  });

  const toggleSubmenu = (menu) => {
    setOpenSubmenus((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  return (
    <aside className="bg-gray-800 text-white w-64 p-6 min-h-screen">
      <nav>
        <ul>
          <li>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                `block py-2 px-4 rounded ${isActive ? 'bg-gray-700' : ''}`
              }
            >
              Dashboard
            </NavLink>
          </li>

          {/* Students with submenu */}
          <li>
            <button
              onClick={() => toggleSubmenu('students')}
              className="block py-2 px-4 rounded w-full text-left"
            >
              Students
            </button>
            {openSubmenus.students && (
              <ul className="pl-4">
                <li>
                  <NavLink
                    to="/students/create"
                    className={({ isActive }) =>
                      `block py-2 px-4 rounded ${isActive ? 'bg-gray-700' : ''}`
                    }
                  >
                    Create Student
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/students/list"
                    className={({ isActive }) =>
                      `block py-2 px-4 rounded ${isActive ? 'bg-gray-700' : ''}`
                    }
                  >
                    List Students
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          {/* Settings with submenu */}
          <li>
            <button
              onClick={() => toggleSubmenu('settings')}
              className="block py-2 px-4 rounded w-full text-left"
            >
              Settings
            </button>
            {openSubmenus.settings && (
              <ul className="pl-4">
                <li>
                  <NavLink
                    to="/settings/profile"
                    className={({ isActive }) =>
                      `block py-2 px-4 rounded ${isActive ? 'bg-gray-700' : ''}`
                    }
                  >
                    Profile Settings
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/settings/account"
                    className={({ isActive }) =>
                      `block py-2 px-4 rounded ${isActive ? 'bg-gray-700' : ''}`
                    }
                  >
                    Account Settings
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li>
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                `block py-2 px-4 rounded ${isActive ? 'bg-gray-700' : ''}`
              }
            >
              Profile
            </NavLink>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
