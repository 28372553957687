// src/components/Header.js
import React from 'react';
import { useAuth } from '../context/authContext';

const Header = () => {
  const { logout } = useAuth();

  return (
    <header className="bg-gray-800 text-white p-4 shadow-md w-full">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-xl font-bold">Dashboard</h1>
        <button className="bg-blue-500 px-4 py-2 rounded" onClick={logout}>Logout</button>
      </div>
    </header>
  );
};

export default Header;
